$fonts: "Lato", sans-serif, opensans, "Segoe UI", "Helvetica Neue", sans-serif;
$montserrat: "Montserrat", "Open Sans", opensans, "Segoe UI", "Helvetica Neue", sans-serif;

// Breakpoints
$bp-xxs: 374px;
$bp-xs: 400px;
$bp-sm: 640px;
$bp-md: 767px;
$bp-md-l: 992px;
$bp-lg: 1024px;
$bp-xl: 1140px;


// Font Sizes
$font-weight-bold: 700;

// Font Sizes
$font-size-lg: 24px;
$font-size-sm: 14px;



// Main 3 colours
$primary: #1fa96d;
$secondary: #0097d9;
$tertiary: #008dd5;

$darkblue: #14233c;
$lightblue: #427AA1;

$bg: #f2f3f6;

$blue: #0083d9;
$blue2: #247ba0;
$blue3: #00a8e8;

$purple: #413C58;

$pink: #EF476F;
$pink2: #eb2d62;
$ccpink: #d6345f;

$orange: #f6751d;
$darkorange: #b3244a;
$orange2: #fa7921;

$green: #1fa96d;
$buttongreen: #1FA96D;

$red: #BD3838;
$red2: #f25f5c;
$red3: #e71d36;

$bp-1: 1200px;
$bp0: 1100px;
$bp1: 1016px;
$bp2: 700px;
$bp3: 500px;

$bp-xs: 400px;
$bp-sm: 576px;
$bp-md: 768px;
$bp-lg: 992px;
$bp-xl: 1200px;

$fonts: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;



// Colours
$black-darker:   #0D1318;
$black-dark:     #10151a;
$black:          #14191E;
$black-light:    #181B1E;
$black-lighter:  #191E24;
$black-lightest: #202429;
$black-darkest:  #00081e;
$dark-pink:      #e64887;

$blue-bg:   #3e8ef6;
$blue-darkest:   #142e5e;
$blue-lightest:  #f6fafd;
$dark-grayish:   #55617e;
$light-gray:     #e5e8f0;
$blue-text:    #48bebd;
$light-border-color: #b8c2d6;
$light-blue-gradient: rgba(98,183,237,.2);
$light-blue-gradient2: rgba(72,190,189,.2);
$light-pink-gradient: rgba(250, 62, 27, 0.06);
$light-yellow-gradient: rgba(254, 227, 60, 0.6);
$light-blue: #3e8ef6;
$lead-gray: #e5e8f0;
$dark-green: #489d00;
$gray-shade: #f7f9fa;
$blue-bg-light: #f7fafc;


$grey-900:      #212934;
$grey-800:      #404b5a;
$grey-700:      #6e7a8a;
$grey-600:      #929fb1;
$grey-500:      #aebecd;
$grey-400:      #cad2d9;
$grey-300:      #d5dde5;
$grey-200:      #e1e7ec;
$grey-100:      #f8fafc;

$white: #fff;

$red-darkest:  #3B0D0C;
$red-darker:   #621B18;
$red-dark:     #CC1F1A;
$red:          #E3342F;
$red-light:    #Ef5753;
$red-lighter:  #F9ACAA;
$red-lightest: #FCEBEA;

$orange-darkest:  #462A16;
$orange-darker:   #613B1F;
$orange-dark:     #DE751F;
$orange:          #F6993F;
$orange-light:    #FAAD63;
$orange-lighter:  #FCD9B6;
$orange-lightest: #FFF5EB;

$yellow-darkest:  #fee33c;
$yellow-darker:   #684F1D;
$yellow-dark:     #F2D024;
$yellow:          #FFED4A;
$yellow-light:    #FFF382;
$yellow-lighter:  #FFF9C2;
$yellow-lightest: #FCFBEB;

$green-darkest:   #0F2F21;
$green-darker:    #1A4731;
$green-dark:      #1F9D55;
$green:           #38C172;
$green-light:     #51D88A;
$green-lighter:   #A2F5BF;
$green-lightest:  #E3FCEC;

$bluegreen-darkest:   #003026;
$bluegreen-darker:    #14523b;
$bluegreen-dark:      #2a7e5f;
$bluegreen:           #3dae84;
$bluegreen-light:     #54cfa2;
$bluegreen-lighter:   #a0e9ce;
$bluegreen-lightest:  #e0f5ed;

$teal-darkest:   #0D3331;
$teal-darker:    #20504F;
$teal-dark:      #38A89D;
$teal:           #4DC0B5;
$teal-light:     #64D5CA;
$teal-lighter:   #A0F0ED;
$teal-lightest:  #E8FFFE;

$blue-darker:    #1C3D5A;
$blue-dark:      #2779BD;
$blue:           #3490DC;
$blue-light:     #6CB2EB;
$blue-lighter:   #BCDEFA;

$indigo-darkest:   #191E38;
$indigo-darker:    #2F365F;
$indigo-dark:      #5661B3;
$indigo:           #6574CD;
$indigo-light:     #7886D7;
$indigo-lighter:   #B2B7FF;
$indigo-lightest:  #E6E8FF;

$purple-darkest:   #21183C;
$purple-darker:    #382B5F;
$purple-dark:      #794ACF;
$purple:           #9561E2;
$purple-light:     #A779E9;
$purple-lighter:   #D6BBFC;
$purple-lightest:  #F3EBFF;

$pink-darkest:   #451225;
$pink-darker:    #6F213F;
$pink-dark:      #EB5286;
$pink:           #F66D9B;
$pink-light:     #FA7EA8;
$pink-lighter:   #FFBBCA;
$pink-lightest:  #FFEBEF;

// Legacy colours

$darkgrey:     #24292e;
$secondgrey:   #424446;
$midgrey:      #97999b;
$hrgrey:       #c4c6c8;
$bordergrey:   #caccce;
$topbarborder: #e0e6e7;
$bggrey:       #ebeef2;

$ccpink: #d6345f;

$linkblue: #2c9ab7;
$headerblue: #125b86;
$tutblue: #2274A5;

$toolsphone: #E73700;
$toolsfaq: #BD3838;
$toolsaccount: #3888BD;

$tutgreen: #32936F;

$tutorange: #FF7400;
$deleteorange: #ff3400;

$pensions: #14AEE9;
$mortgages: #1434DF;
$protection: #272A3B;
$tax: #B60016;
$investments: #FC5A31;

$yellow-alt: #ffc533 /* #EFB317 */;
$purple-alt: #593271;

$border-color: #e7e9eb;


$badge-green-light: #cbf4c9;
$badge-green-dark: #116245;

$badge-blue-light: #d7ebff;
$badge-blue-dark: #2f64c1;

$badge-purple-light: #C0ADFF;
$badge-purple-dark: #703DA3;

$badge-orange-light: #f2cbb5;
$badge-orange-dark: #b35400;

$badge-red-light: #fde2dd;
$badge-red-dark: #a72353;

$badge-yellow-light: #f6e6b9;
$badge-yellow-dark: #983704;
