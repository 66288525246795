@import 'variables';


html {
  font-size: 16px !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $montserrat, $fonts;
  font-weight: 500;
  color: #333;
  letter-spacing: -.05rem;
}

h2 {
  font-size: 28px;
}

.full {
  width: 100%;
}



.logo-blue {
  max-width: 124px;
}

.header-login {
  border-radius: 6px !important;
  border: 1px solid #555;
}

.header {
  position: absolute;
  background-color: $darkblue;
  background-image: none !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  border-bottom: none;
}

.hp {
  .search-input-wrap {
    max-width: 100%;
    width: 100%;
    box-shadow: 0 5px 30px rgba(156, 160, 171, 0.25);
    border-radius: 3px;
    border: 1px solid #ddd;
    display: flex;
    background-color: #fff;
    @media(max-width: 470px) {
      margin-top: 50px;
    }
    .btn {
      margin-top: -1px;
      margin-bottom: -1px;
      margin-right: -1px;
      border-radius: 0 3px 3px 0;
      min-width: 100px;
      
    }
  }
  .location-wrap {
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: 1px;
    background-color: #eee;
    text-align: center;
    align-items: center;
    padding: 0 .6rem;
    border-left: 1px solid #ccc;
    display: none;
    &.visible {
      display: flex;
    }
  }
  .search-input-wrap .fa {
    position: static;
    color: #666;
  }
}
.featurette.first {
  background-color: #fafafa;
  background-image: none !important;
  background-size: cover;
  h1 {
    color: #444;
    font-size: 2.8rem;
    line-height: 1.1;
    @media(max-width: 800px) {
      font-size: 2rem;
    }
  }
}

.main-search {
  padding: 14px 19px;
  max-width: 100%;
  box-shadow: none;
  border: none !important;
  margin-bottom: 1px;
  flex-grow: 1;
  max-width: 100%;
  @media(max-width: 500px) {
    padding: 12px 8px;
  }
  &:focus {
    border: none !important;
    box-shadow: none;
  }
}
.first .hp-container {
  padding: 230px 1rem;
  @media(max-width: 1024px) {
    padding: 150px 1rem;
  }
}
.hp-container {
  padding: 180px 1rem;
  @media(max-width: 1024px){
    padding: 70px 1rem 180px;
  }
  &.wide {
    max-width: 1100px;
  }
  .btn {
    border-color: rgba(0,0,0,.25);
  }
  &.small {
    padding: 100px 1rem;
  }
}

.hp-svg svg {
  display: block;
  max-width: 100% !important;
  margin: 0 auto;
}
svg {
  max-width: 100%;
}
.sub-heading {
  font-size: 1.07rem;
  color: #888;
  font-weight: 400 !important;
}
.ab {
  font-family: $montserrat;
  font-weight: 600;
  color: #aaa;
  font-size: 18px;
  margin-bottom: .25rem;
}
p.marketing {
  font-size: 18px;
  color: #555;
}
.featurette .row {
  align-items: center;
  .col-lg-6 {
    @media(max-width: $bp-lg) {
      margin-bottom: 2rem;
    }
  }
}

.featurette:not(.white) {
  background-color: #fafafa;
}
b {
  font-weight: 600;
}
.row svg {
  @media (max-width: 800px) {
    display: none;
  }
}

.benefit {
  padding: 1rem;
  text-align: center;
}
.benefit-icon {
  font-size: 54px;
}
.benefit-title {
  font-weight: 500;
  font-size: 18px;
  color: #222;
}
.benefit-body {
  font-size: 15px;
  color: #555;
}

.alert {
      border-left: 2px solid #8bb4dd;
      border-radius: 3px;
      background-color: #deeefe;
      color: #014890;
      padding: 1rem;

      display: inline-block;
      vertical-align: top;
      font-size: 14px;
      line-height: 1.5;
}


@media only screen and( max-width: 1699px) {
  .hp-container {
    max-width: 70%;
    box-sizing: border-box!important;
  }
}
@media only screen and( max-width: 937px) {
  .hp-container {
    margin-left: 0;
    
  }
}

@media only screen and (max-width: 468px) {
  .hero-title {
    font-size: 40px!important;
    letter-spacing: 0;

  }

  .hp .search-input-wrap {
    flex-direction: column!important;
  }

  .search-select select, .main-search{
    padding: 15px;
    font-size: 20px;
  }

  .search-btn {
    font-size: 19px;
    padding: 8px;
  }

  


}

/** HERO 2 */
.hero-two {
  padding: 0;
}

.section-title {
  text-align: center;
  color: #14233c!important;
  font-size: 40px;
  letter-spacing: 2px;
  margin-bottom: 5px;
  font-weight: 700;
  padding: 50px;

}

.theme-color {
  color: #14233c!important;
}

.benefit-title {
  margin-top: 10px;
  font-size: 20px!important;
  font-weight: 700!important;
}

.benefit-body {
  font-size: 18px!important;
}

.first {
  margin-top: 70px;
}
.hp-container {
  max-width: 1600px!important;

}

.content {
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
}
 .hero-title {
   color: #14233c!important;
   font-size: 25px;
   letter-spacing: 2px;
   margin-bottom: 5px;
   font-weight: 700;
}

.sec-title {
color: #14233c!important;
font-size: 25px;
letter-spacing: 1px;
margin-bottom: 10px;
font-weight: 600;

}
.sec-text {
font-size: 18px;
color: #4c4c4c;
text-align: justify;
}

@media(max-width: 991px) {
.section-title {
  font-size: 30px!important;
} 
.sec-text {
   font-size: 16px;
 }
}


@media(max-width: 342px) {
 .section-title {
   font-size: 27px!important;
 }
}


.content-cont {
 padding: 30px 0;
}

.accent-bg {
 background-color:rgba(10, 80, 161, 0.055);
}

.sub-heading {
 color: #14233c!important;
}

.select select {
 background-color: rgb(0, 255, 179)!important;
 color: #14233c!important;
 font-weight: 700!important;
 font-size: 17px;
}



.hero-right-asset img{
 height: auto;
 width: auto;

}

.small-section-asset img {
 height: 300px;
}

@media(max-width: 1199px) {
  .small-section-asset{
    margin-left: 40px!important;
  }
}




@media only screen and (max-width: 1041px) {
.first {
  margin-top: 0;
} 
}

@media only screen and (max-width: 978px) {

 
}
@media only screen and (max-width: 937px) {
  .hp-wrap {
    display: block!important;
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;
  }
 
}
@media only screen and (max-width: 821px) {
  .hp-wrap {
    max-width: 80%;

  }
 
}
@media only screen and (max-width: 801px) {
  .hero-title {
   font-size: 40px!important;

  }
 
}

@media(max-width: 767px)
  {
    .steps {
      flex-direction: flex!important;
      flex-direction: column;
    }

    .rev {
      flex-direction: column-reverse!important;
    }

    .text-content {
      padding: 30px 0;
    }

    .small-section-asset img {
      max-width: 300px!important;
      height: auto!important;
    }

    .small-section-asset{
      margin-left: 0!important;
    }
  } 

@media only screen and (max-width: 685px) {
  .hp-wrap {
    max-width: 90%;

  }
 
}
@media only screen and (max-width: 485px) {
  .search-btn {
    display: block!important;
  }

  }
 


@media only screen and (max-width: 468px) {
  .hero-title {
    font-size: 30px!important;

  }

  .hp .search-input-wrap {
    flex-direction: column!important;
    border: none!important;
    margin-top: 50px!important;
  }
  .hp .search-input-wrap .btn{
    margin: 10px!important;
  }




}

@media(max-width: 366px)
{
  .section-title {
    width: 100%!important;
    padding: 30px 10px!important;
  }

  .hero-right-asset img {
    min-height: 300px!important; 
  }
}